var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.vehicleClasification'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.vehicleClasification'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleShowModalCreateTpVehiculo}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedClassVehicle,"table-filter":_vm.tableText.tableFilterText,"column-filter":"","items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"noItemsView":_vm.tableText.noItemsViewText,"pagination":"","loading":_vm.loadingTable,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+' '+_vm.$t('label.vehicleClasification'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.edit')+' '+$t('label.vehicleClasification'),\n              placement: 'top-end'\n            }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditCLassVehicle(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modalClassVehiculo',{attrs:{"modal":_vm.showModal,"title":_vm.titleModal,"editModal":_vm.editModal,"classVehicleSelected":_vm.classVehicleSelected},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateCLoseModal,"refresh-data-table":_vm.mountedClassVehicle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }